import { FormLabel, Grid, Theme } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { useState } from "react";
import styled, { css } from "styled-components";
import { routes } from "../../common/routes";
import { AppForm } from "../../components/form/AppForm";
import { AppBar } from "../../components/topbar/AppBar";
import { Typography } from "../../components/Typography";
import { LoginForm } from "./LoginForm";
import GoogleButton from "react-google-button";
import { pxToRem } from "../../common/styling-utils";
import { kondohelloLogin } from "./api/login-service";
import { useNavigate } from "../../common/hooks/navigate-hook";

const paperstyle = css<{ theme: Theme }>`
  && {
    background-color: ${(props) => props.theme.palette.secondary.light};
  }
`;

const SignInWithGoogle = styled(GoogleButton)`
  margin-top: ${pxToRem(12)};
  width: 100% !important;
`;

const GridContainer = styled(Grid)`
  margin: ${pxToRem(20)} ${pxToRem(-20)} !important;
`;

export const Login = () => {
  const [loggedInSuccessful, setLoggedInSuccesful] = useState<boolean>(false);
  const { navigate } = useNavigate();

  if (loggedInSuccessful) {
    navigate(routes.dashboard);
  }

  const onGoogleLogin = async () => {
    try {
      await kondohelloLogin("google")();
      setLoggedInSuccesful(true);
    } catch (e) {
      console.log(e);
      navigate(window.location.pathname);
    }
  };

  return (
    <>
      <AppBar />
      <AppForm paperstyle={paperstyle} maxWidth="md">
        <>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Sign In
          </Typography>
          <Typography variant="body2" align="center">
            {"Not a member yet? "}
            <Link href={routes.register} align="center" underline="always">
              Sign Up here
            </Link>
          </Typography>
        </>
        <GridContainer container spacing={5}>
          <Grid item xs={12} sm={6}>
            <LoginForm />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel>Or sign in with other methods:</FormLabel>
            <SignInWithGoogle onClick={onGoogleLogin} />
          </Grid>
        </GridContainer>

        <Typography align="center">
          <Link underline="always" href={routes.forgotPassword}>
            Forgot password?
          </Link>
        </Typography>
      </AppForm>
    </>
  );
};
