import { PropTypes, useTheme } from "@material-ui/core";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { ChangeEvent, ReactNode } from "react";
import { Box } from "reflexbox";
import styled, { FlattenSimpleInterpolation } from "styled-components";
import { pxToRem } from "../../common/styling-utils";
import { AppInputLabel } from "./AppInputLabel";

interface TextFieldWrapperProps {
  fieldstyles?: FlattenSimpleInterpolation;
}

// TODO: add disableUnderline property
const TextFieldWrapper = styled(TextField)<TextFieldWrapperProps>`
  ${(props) => props.fieldstyles}
  width: inherit;
  background-color: white;
  .MuiInput-underline::before {
    border-bottom: none;
  }
`;

interface AppFieldProps {
  variant?: "filled" | "outlined" | "standard";
  fieldstyles?: FlattenSimpleInterpolation;
  labelstyles?: FlattenSimpleInterpolation;
  children?: ReactNode;
  label?: string;
  required?: boolean;
  margin?: PropTypes.Margin;
  autoComplete?: string;
  autoFocus?: boolean;
  fullWidth?: boolean;
  name?: string;
  input?: TextFieldProps;
}

// TODO: destructure props or use others
export const AppField = ({
  input,
  variant,
  fieldstyles,
  label,
  required,
  labelstyles,
  ...others // margin, name, required, size
}: AppFieldProps) => {
  const theme = useTheme();

  const onFieldChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (input && input.onChange) {
      input.onChange(event);
    }
  };

  return (
    <Box pt={pxToRem(8)} pb={pxToRem(8)} width={"100%"}>
      <AppInputLabel
        theme={theme}
        label={label}
        labelstyles={labelstyles}
        shrink
        required
      />
      <TextFieldWrapper
        theme={theme}
        variant={variant}
        fieldstyles={fieldstyles}
        onChange={onFieldChange}
        {...input}
        {...others}
      />
    </Box>
  );
};
