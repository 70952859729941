import "firebase/auth";
import { Field, Form } from "react-final-form";
import { generatePath } from "react-router";
import { Box, Flex } from "reflexbox";
import { useNavigate } from "../../common/hooks/navigate-hook";
import { routes } from "../../common/routes";
import { pxToRem } from "../../common/styling-utils";
import { AppField } from "../../components/form/AppField";
import { FormButton } from "../../components/form/FormButton";
import { kondohelloSignUp } from "./api/register-firebase-service";
import { RegisterValues, UserType } from "./api/register-types";

interface EmailRegisterProps {
  userType: UserType;
}

export const EmailRegister = ({ userType }: EmailRegisterProps) => {
  const { navigate } = useNavigate();

  const onSubmit = async (values: RegisterValues) => {
    try {
      await kondohelloSignUp("email")(values);
      navigate(generatePath(routes.registerForm, { userType }));
    } catch (e) {
      console.log(e);
      navigate(window.location.pathname);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Flex flexDirection={"column"}>
            <Field name={"email"} component={AppField} label="Email" />
            <Field name={"password"} component={AppField} label="Password" />
            <Field
              name={"confirmPassword"}
              component={AppField}
              label="Confirm password"
            />
            <Box pt={pxToRem(16)}>
              <FormButton
                disabled={false}
                size={"large"}
                color={"secondary"}
                mounted
                fullWidth
              >
                Sign up
              </FormButton>
            </Box>
          </Flex>
        </form>
      )}
    </Form>
  );
};
