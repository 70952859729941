import { Theme, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import { ReactNode } from "react";

const StyledButton = styled(Button)<StyledButtonProps>`
  && {
    border-radius: 0;
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    font-family: ${(props) => props.theme.typography.fontFamilySecondary};
    padding: ${(props) => props.theme.spacing(2, 4)};
    font-size: ${(props) => props.theme.typography.pxToRem(14)};
    &:active, &:focus: {
      box-shadow: none;
    }
  }

  &.sizeSmall {
    padding: ${(props) => props.theme.spacing(1, 3)};
    font-size: ${(props) => props.theme.typography.pxToRem(13)};
  }

  &.sizeBig {
    padding: ${(props) => props.theme.spacing(2, 5)};
    font-size: ${(props) => props.theme.typography.pxToRem(16)};
  }
`;

export type ButtonSize = "small" | "medium" | "large";
export type ButtonColor = "inherit" | "primary" | "secondary" | "default";
export type ButtonVariant = "text" | "outlined" | "contained";
export type ButtonType = 'submit' | 'reset' | 'button';

interface AppButtonProps {
  children: ReactNode;
  color: ButtonColor;
  variant: ButtonVariant;
  size: ButtonSize
  href?: string;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
  onClick?: () => void;
}

interface StyledButtonProps extends AppButtonProps {
  theme: Theme;
}

export const AppButton = ({ children, ...rest }: AppButtonProps) => {
  const theme = useTheme();

  return (
    <StyledButton theme={theme} {...rest}>
      {children}
    </StyledButton>
  );
};
