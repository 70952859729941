import { ReactNode } from "react";
import { AppButton, ButtonColor, ButtonSize } from "../AppButton";

interface FormButtonProps {
  disabled: boolean;
  mounted?: boolean;
  size: ButtonSize;
  color: ButtonColor;
  fullWidth: boolean;
  children: ReactNode;
  onClick?: () => void;
}

export const FormButton = ({
  disabled,
  mounted,
  children,
  ...others
}: FormButtonProps) => {
  return (
    <AppButton
      disabled={!mounted || disabled}
      type="submit"
      variant="contained"
      {...others}
    >
      {children}
    </AppButton>
  );
};
