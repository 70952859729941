import { LoginMode, LoginCredentials, LoginFunc } from './login-types';
import firebase from "firebase";

const loginWithEmailAndPassword = async (credentials: LoginCredentials) => {
    const auth = firebase.auth();
    const { email, password } = credentials;
    return await auth.signInWithEmailAndPassword(email, password);
}

const loginWithGoogle = async (_cred?: LoginCredentials) => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return await firebase.auth().signInWithPopup(provider);
}

// TODO add validation
export const kondohelloLogin = (mode: LoginMode) => {
    const loginServices: Record<LoginMode, LoginFunc> = {
        email: loginWithEmailAndPassword as LoginFunc,
        google: loginWithGoogle,
    }

    return loginServices[mode];
}