import { kondohelloLogin } from './../../login/api/login-service';
import { LoginFunc, LoginMode } from '../../login/api/login-types';
import firebase from 'firebase/app';
import 'firebase/auth';
import { RegisterValues } from './register-types';

type SignUpFunction = (registerValues?: RegisterValues) => Promise<firebase.auth.UserCredential>;

const signUpWithEmailAndPassword = async ({ email, password, confirmPassword }: RegisterValues) => { 
    // TODO add more validation
    if (password === confirmPassword) {
        return await firebase.auth().createUserWithEmailAndPassword(email, password);
    } else {
        // TODO use custom error
        throw Error('Unmatch password')
    }
}

export const kondohelloSignUp = (mode: LoginMode) => {
    const signUpFactory: Record<LoginMode, SignUpFunction | LoginFunc> = {
        email: signUpWithEmailAndPassword as SignUpFunction,
        google: kondohelloLogin('google') 
    }

    return signUpFactory[mode];
}