import { Container, Theme, useTheme } from "@material-ui/core";
import React, {
  ReactChild,
  ReactFragment,
  ReactNode,
  ReactPortal,
} from "react";
import styled, { FlattenSimpleInterpolation } from "styled-components";

interface StyledSectionProp {
  theme: Theme;
  backgroundStyle: FlattenSimpleInterpolation;
}
export const StyledSection = styled.section<StyledSectionProp>`
  color: ${(props) => props.theme.palette.common.white};
  position: relative;
  display: flex;
  align-items: center;
  ${(props) => props.backgroundStyle}
  ${(props) => props.theme.breakpoints.up("sm")} {
    height: 80vh;
    min-height: 500px;
    max-height: 1300px;
  }
`;

const Backdrop = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.palette.common.black};
  opacity: 0.5;
  z-index: -1;
`;

const Background = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -2;
`;
interface StyledContainerProps {
  theme: Theme;
  children: (boolean | ReactChild | ReactFragment | ReactPortal) & ReactNode;
}

// TODO: use theme spacing for margin
const StyledContainer = styled(Container)<StyledContainerProps>`
  && {
    margin-top: 15px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

// TODO: use theme spacing for bottom
const ArrowDown = styled.img`
  && {
    position: absolute;
    bottom: 15px;
    width: 12px;
    height: 16px;
  }
`;

interface ProductHeroLayoutProps {
  backgroundStyle: FlattenSimpleInterpolation;
  children: (boolean | ReactChild | ReactFragment | ReactPortal) & ReactNode;
}

export const ProductHeroLayout = ({
  children,
  backgroundStyle,
}: ProductHeroLayoutProps) => {
  const theme = useTheme();

  // TODO: add arrow down?
  return (
    <StyledSection theme={theme} backgroundStyle={backgroundStyle}>
      <StyledContainer theme={theme}>
        {children}
        <Backdrop theme={theme} />
        <Background />
        <ArrowDown
          theme={theme}
          src="/productHeroArrowDown.png"
          alt="arrow down"
        />
      </StyledContainer>
    </StyledSection>
  );
};
