import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Theme,
} from "@material-ui/core";
import React, { useState } from "react";
import styled, { css } from "styled-components";
import { routes } from "../../common/routes";
import { pxToRem } from "../../common/styling-utils";
import { AppForm } from "../../components/form/AppForm";
import { AppBar } from "../../components/topbar/AppBar";
import { Typography } from "../../components/Typography";
import { EmailRegister } from "./EmailRegister";
import GoogleButton from "react-google-button";
import { Box } from "reflexbox";
import { UserType } from "./api/register-types";
import { kondohelloSignUp } from "./api/register-firebase-service";
import { useNavigate } from "../../common/hooks/navigate-hook";
import { generatePath } from "react-router";

const paperstyle = css<{ theme: Theme }>`
  && {
    background-color: ${(props) => props.theme.palette.secondary.light};
  }
`;

const GridContainer = styled(Grid)`
  margin: ${pxToRem(20)} ${pxToRem(-20)} !important;
`;

const SignUpWithGoogle = styled(GoogleButton)`
  margin-top: ${pxToRem(12)};
  width: 100% !important;
`;

export const Register = () => {
  const [userType, setUserType] = useState<UserType>("tenant");
  const { navigate } = useNavigate();

  const onTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    _value: string
  ) => {
    setUserType(event.target.value as UserType);
  };

  const onGoogleSignUp = async () => {
    try {
      await kondohelloSignUp("google")();
      navigate(generatePath(routes.registerForm, { userType }));
    } catch (e) {
      console.log(e);
      navigate(window.location.pathname);
    }
  };

  return (
    <>
      <AppBar />
      <AppForm paperstyle={paperstyle} maxWidth="md">
        <Typography variant="h3" gutterBottom marked="center" align="center">
          Sign Up
        </Typography>
        <Typography variant="body2" align="center">
          <Link href={routes.login} align="center" underline="always">
            Already have an account?
          </Link>
        </Typography>
        <Box pt={pxToRem(16)}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Are you a </FormLabel>
            <RadioGroup
              row
              aria-label="gender"
              name="row-radio-buttons-group"
              value={userType}
              onChange={onTypeChange}
            >
              <FormControlLabel
                value="tenant"
                control={<Radio />}
                label="Tenant"
              />
              <FormControlLabel
                value="owner"
                control={<Radio />}
                label="Owner"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <GridContainer container spacing={5}>
          <Grid item xs={12} sm={6}>
            <FormLabel>Sign up with email:</FormLabel>
            <Box pt={pxToRem(16)}>
              <EmailRegister userType={userType} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel>Or sign up with other methods:</FormLabel>
            <SignUpWithGoogle onClick={onGoogleSignUp} />
          </Grid>
        </GridContainer>
      </AppForm>
    </>
  );
};
