import { Box, Container, Paper, Theme, useTheme } from "@material-ui/core";
import React, { ReactNode } from "react";
import styled, {
  FlattenInterpolation,
  ThemedStyledProps,
} from "styled-components";

const RootDiv = styled.div`
  display: flex;
  background-image: url(/appCurvyLines.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

interface StyledPaperProps {
  theme: Theme;
  paperstyle?: FlattenInterpolation<ThemedStyledProps<{ theme: Theme }, any>>;
}
const StyledPaper = styled(Paper)<StyledPaperProps>`
  ${(props) => props.paperstyle}
  padding: ${(props) => props.theme.spacing(4, 3)};
  ${(props) => props.theme.breakpoints.up("md")}: {
    padding: ${(props) => props.theme.spacing(8, 6)};
  }
`;
interface AppFormProps {
  children: ReactNode;
  paperstyle?: FlattenInterpolation<ThemedStyledProps<{ theme: Theme }, any>>;
  maxWidth?: false | "md" | "xs" | "sm" | "lg" | "xl" | undefined;
}

export const AppForm = ({ children, paperstyle, maxWidth }: AppFormProps) => {
  const theme = useTheme();

  return (
    <RootDiv>
      <Container maxWidth={maxWidth}>
        <Box mt={7} mb={12}>
          <StyledPaper theme={theme} paperstyle={paperstyle}>
            {children}
          </StyledPaper>
        </Box>
      </Container>
    </RootDiv>
  );
};
