import { InputLabel, Theme } from "@material-ui/core";
import React from "react";
import styled, { FlattenSimpleInterpolation } from "styled-components";

const LabelWrapper = styled(InputLabel)<{
  labelstyles?: FlattenSimpleInterpolation;
}>`
  ${(props) => props.labelstyles}
`;

interface AppInputLabelProps {
  theme: Theme;
  labelstyles?: FlattenSimpleInterpolation;
  label?: string;
  shrink?: boolean;
  required?: boolean;
}

export const AppInputLabel = ({
  theme,
  labelstyles,
  label,
  ...others
}: AppInputLabelProps) => {
  // TODO: required doesnt appear
  return (
    <LabelWrapper theme={theme} labelstyles={labelstyles} {...others}>
      {label}
    </LabelWrapper>
  );
};
