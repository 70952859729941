import React from "react";
import { AppBar } from "../../components/topbar/AppBar";
import { ProductHero } from "./ProductHero";

export const Landing = () => {
  return (
    <>
      <AppBar />
      <ProductHero />
    </>
  );
};
