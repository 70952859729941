import React from "react";
import { Link as MaterialLink, Theme, useTheme } from "@material-ui/core";
import styled from "styled-components";
import { AppToolbar as Toolbar, toolbarStyles } from "./AppToolbar";
import { Topbar } from "./Topbar";
import { routes } from "../../common/routes";

const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
`;
const LeftDiv = styled.div`
  flex: 1;
`;
const Title = styled(MaterialLink)`
  && {
    font-size: 24px;
  }
`;
const RightDiv = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;
const RightLink = styled(MaterialLink)<{ theme: Theme }>`
  && {
    font-size: 16px;
    margin-left: ${(props) => props.theme.spacing(3)}px;
  }
  color: ${(props) => props.theme.palette.common.white};
`;
const SecondaryLink = styled(RightLink)<{
  theme: Theme;
}>`
  && {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;
const Placeholder = styled.div<{ theme: Theme }>`
  ${toolbarStyles}
`;

export const AppBar = () => {
  const theme = useTheme();

  return (
    <>
      <Topbar position="fixed">
        <StyledToolbar>
          <LeftDiv />
          <Title variant="h6" underline="none" color="inherit" href="/">
            {"kondohello"}
          </Title>
          <RightDiv>
            <RightLink
              theme={theme}
              color="inherit"
              variant="h6"
              underline="none"
              href={routes.login}
            >
              {"Sign In"}
            </RightLink>
            <SecondaryLink
              theme={theme}
              variant="h6"
              underline="none"
              href={routes.register}
            >
              {"Sign Up"}
            </SecondaryLink>
          </RightDiv>
        </StyledToolbar>
      </Topbar>
      <Placeholder theme={theme} />
    </>
  );
};
