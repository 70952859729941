import { Form, Field } from "react-final-form";
import { Flex } from "reflexbox";
import { useNavigate } from "../../common/hooks/navigate-hook";
import { routes } from "../../common/routes";
import { AppField } from "../../components/form/AppField";
import { FormButton } from "../../components/form/FormButton";
import { kondohelloLogin } from "./api/login-service";
import firebase from "firebase/app";
import "firebase/auth";
import { LoginCredentials } from "./api/login-types";

export const LoginForm = () => {
  const { navigate } = useNavigate();

  const onSubmit = async (values: LoginCredentials) => {
    try {
      await kondohelloLogin("email")(values);
      const user = firebase.auth().currentUser;
      if (user) {
        navigate(routes.dashboard);
      } else {
        navigate(window.location.pathname);
      }
    } catch (e) {
      // TODO show error message
      console.log(e);
      navigate(window.location.pathname);
    }

    const user = firebase.auth().currentUser;
    if (user) {
      navigate(routes.dashboard);
    } else {
      navigate(window.location.pathname);
    }
  };

  return (
    // TODO solve the mistery: why onSubmit is not invoked when handleSubmit from props is used
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Flex flexDirection={"column"}>
            <Field name={"email"} component={AppField} label="Email" />
            <Field name={"password"} component={AppField} label="Password" />
            <FormButton
              disabled={false}
              size={"large"}
              color={"secondary"}
              mounted
              fullWidth
            >
              Sign in
            </FormButton>
          </Flex>
        </form>
      )}
    </Form>
  );
};
