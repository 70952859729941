import React, { ReactNode } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import styled, { css } from "styled-components";
import { Theme } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
// TODO: rename component, what does this component actually do?
interface WrapperProp {
  theme: Theme;
}
export const toolbarStyles = css`
  height: 64px;
  ${(props) => props.theme.breakpoints.up("sm")} {
    height: 70px;
  }
`;

const Wrapper = styled(Toolbar)<WrapperProp>`
  ${toolbarStyles}
`;

export interface AppToolbarProps {
  children?: ReactNode;
}

export const AppToolbar = ({ children }: AppToolbarProps) => {
  const theme = useTheme();

  return <Wrapper theme={theme}>{children}</Wrapper>;
};
