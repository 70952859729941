import React from "react";
import "./App.css";
import { FirebaseAppProvider } from "reactfire";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { Landing } from "./views/landing/Landing";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./common/theme/theme";
import { routes } from "./common/routes";
import { Login } from "./views/login/Login";
import { Register } from "./views/register/Register";
import { ForgotPassword } from "./views/forgot-password/ForgotPassword";
import { Dashboard } from "./views/dashboard/Dashboard";
import { FirebaseAuthProvider } from "@react-firebase/auth";
import firebase from "firebase/app";
import "firebase/auth";
import { RegisterForm } from "./views/register/RegisterForm";

const firebaseConfig = {
  apiKey: "AIzaSyADqExdFuoGh9P9PqM71lx4jGWp5fBOkJ0",
  authDomain: "ekos-frontend.firebaseapp.com",
  projectId: "ekos-frontend",
  storageBucket: "ekos-frontend.appspot.com",
  databaseURL: "https://ekos-frontend.firebaseio.com",
  messagingSenderId: "539280281634",
  appId: "1:539280281634:web:dcfd535c3e0ba37f573a7b",
  measurementId: "G-H4H1PV6XJB",
};

function App() {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              <Route exact path="/">
                <Redirect to={routes.landing} />
              </Route>
              <Route path={routes.landing}>
                <Landing />
              </Route>
              <Route path={routes.login}>
                <Login />
              </Route>
              <Route exact path={routes.register}>
                <Register />
              </Route>
              <Route path={routes.forgotPassword}>
                <ForgotPassword />
              </Route>
              <Route path={routes.dashboard}>
                <Dashboard />
              </Route>
              <Route path={routes.registerForm}>
                <RegisterForm />
              </Route>
            </Switch>
          </Router>
        </ThemeProvider>
      </FirebaseAuthProvider>
    </FirebaseAppProvider>
  );
}

export default App;
