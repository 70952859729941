import { Theme, useTheme } from "@material-ui/core/styles";
import React from "react";
import styled, { css } from "styled-components";
import { routes } from "../../common/routes";
import { AppButton as Button } from "../../components/AppButton";
import { Typography } from "../../components/Typography";
import { ProductHeroLayout } from "./ProductHeroLayout";

const backgroundImage =
  "https://images.unsplash.com/photo-1449247709967-d4461a6a6103?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1351&q=80";

const backgroundStyle = css`
  background-image: url(${backgroundImage});

  background-color: #7fc7d9;
  background-position: center;
`;

// TODO: use theme spacing
const TypographyH5 = styled(Typography)<{ theme: Theme }>`
  && {
    margin-bottom: 20px;
    margin-top: 20px;
    ${(props) => props.theme.breakpoints.up("sm")} {
      margin-top: 50px;
    }
  }
`;

// TODO: use theme spacing
const MoreTypography = styled(Typography)<{ theme: Theme }>`
  && {
    margin-top: 10px;
  }
`;

const StyledButton = styled(Button)`
  && {
    min-width: 200px;
  }
`;

// TODO: rename component
export const ProductHero = () => {
  const theme = useTheme();

  return (
    <ProductHeroLayout backgroundStyle={backgroundStyle}>
      <img
        style={{ display: "none" }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Typography
        color="textSecondary"
        align="center"
        variant="h2"
        marked="center"
      >
        Where Owners and Tenants Connect
      </Typography>
      <TypographyH5
        color="textPrimary"
        align="center"
        variant="h5"
        marked="center"
        theme={theme}
      >
        We take care of your hassles so you can focus on the things that matter
      </TypographyH5>
      <StyledButton
        color="secondary"
        variant="contained"
        size="large"
        href={routes.register}
      >
        Register
      </StyledButton>
      <MoreTypography variant="body2" color="primary" theme={theme}>
        Join and connect now
      </MoreTypography>
    </ProductHeroLayout>
  );
};
