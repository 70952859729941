import React, { ReactNode } from "react";
import MuiTypography from "@material-ui/core/Typography";
import { Variant } from "@material-ui/core/styles/createTypography";
import styled, {
  css,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";
import { PropTypes, Theme, useTheme } from "@material-ui/core";
import { capitalize } from "@material-ui/core/utils";

const variantMapping = {
  h1: "h1",
  h2: "h1",
  h3: "h1",
  h4: "h1",
  h5: "h3",
  h6: "h2",
  subtitle1: "h3",
};

// TODO: use theme spacing
const styleMapper: Record<string, FlattenInterpolation<ThemeProps<any>>> = {
  markedH2Center: css`
    && {
      height: 4;
      width: 73;
      display: block;
      margin: 5px auto 0;
      background-color: ${(props) => props.theme.palette.secondary.main};
    }
  `,
  markedH3Center: css`
    && {
      height: 4;
      width: 55;
      display: block;
      margin: 5px auto 0;
      background-color: ${(props) => props.theme.palette.secondary.main};
    }
  `,
  markedH4Center: css`
    && {
      height: 4;
      width: 55;
      display: block;
      margin: 5px auto 0;
      background-color: ${(props) => props.theme.palette.secondary.main};
    }
  `,
  markedH6Center: css`
    && {
      height: 2;
      width: 28;
      display: block;
      margin-top: 2px;
      background: currentColor;
    }
  `,
};

const StyledSpan = styled.span<{
  elementStyle?: FlattenInterpolation<ThemeProps<any>>;
  theme: Theme;
}>`
  ${(props) => (props.elementStyle ? props.elementStyle : "")}
`;

interface TypographyProps {
  children: ReactNode;
  marked: string;
  variant: "inherit" | Variant;
  color:
    | "initial"
    | "inherit"
    | "primary"
    | "secondary"
    | "textPrimary"
    | "textSecondary"
    | "error";
  align: PropTypes.Alignment;
  gutterBottom: boolean;
}

export const Typography = ({
  children,
  marked,
  variant,
  ...rest
}: Partial<TypographyProps>) => {
  const theme = useTheme();
  const style = `marked${
    capitalize(String(variant)) + capitalize(marked ? marked : "")
  }`;
  return (
    <MuiTypography variantMapping={variantMapping} variant={variant} {...rest}>
      {children}
      {marked ? (
        <StyledSpan theme={theme} elementStyle={styleMapper[style]} />
      ) : null}
    </MuiTypography>
  );
};
