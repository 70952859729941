import React, { ReactNode } from "react";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import MuiAppBar from "@material-ui/core/AppBar";

interface TopbarWrapperProps {
  wrappercolor: string;
}

const TopbarWrapper = styled(MuiAppBar)<TopbarWrapperProps>`
  color: ${(props) => props.wrappercolor};
`;
export interface TopbarProps {
  children?: ReactNode;
  position?: "fixed" | "absolute" | "sticky" | "static" | "relative";
}

// TODO: make it responsive?
export const Topbar = ({ position, ...rest }: TopbarProps) => {
  const theme = useTheme();

  return (
    <TopbarWrapper wrappercolor={theme.palette.common.white}>
      <MuiAppBar elevation={0} position={position} {...rest} />
    </TopbarWrapper>
  );
};
